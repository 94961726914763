import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import BlogCard from "../components/blog-card.jsx"
import { graphql } from "gatsby"
import Navigation from "../components/navigation"

export const blogQuery = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    blogs: allContentfulBlog(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
          title
          createdAt
          content {
            raw
          }
          fields {
            slug
            excerpt
          }
        }
      }
    }
  }
`

export default function blog({ data: { blogs }, pageContext }) {
  return (
    <>
      <Seo title="Blog" />
      <BlogGrid>
        {blogs.edges.map(({ node }) => (
          <BlogCard key={node.id} props={node} />
        ))}
      </BlogGrid>
      <Navigation pageContext={pageContext} path="blog" />
    </>
  )
}

const BlogGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 6rem 0 0;

  @media screen and (max-width: 1120px) {
    margin: 5rem 5vw;
  }
  & > * {
    flex: 0 0 calc(33.33% - 2rem);
    margin-bottom: 4rem;
    &:not(:nth-child(3n)) {
      margin-right: 2rem;
    }
    @media screen and (max-width: 880px) {
      flex: 0 0 calc(50% - 2rem);
      margin-bottom: 3rem;
      margin-right: 0 !important;
      &:not(:nth-child(2n)) {
        margin-right: 2rem !important;
      }
    }
    @media screen and (max-width: 580px) {
      flex: 0 0 100%;
      margin-right: 0 !important;
      margin-bottom: 4rem;
    }
  }
`
