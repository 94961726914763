import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "../styles/baseStyles"
import Link from "./link"

export default function BlogCard({
  props: {
    image,
    title,
    fields: { excerpt, slug },
  },
}) {
  return (
    <BlogCardContainer to={`/blog/${slug}`}>
      <Body>
        <Image image={image.gatsbyImageData} placeholder="BLURRED" />
        <Title>{title}</Title>
        <p>{excerpt}</p>
        <Button secondary mini>
          Read More
        </Button>
      </Body>
    </BlogCardContainer>
  )
}

const BlogCardContainer = styled(Link)``

const Image = styled(GatsbyImage)`
  height: 32rem;
  object-fit: cover;
  width: 100%;
  box-shadow: var(--shadow-light);
  border-radius: 0.7rem;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  p {
    margin: 1.5rem 0;
    text-align: justify;
    font-size: 1.5rem;
    padding: 0 0.5rem;
    color: var(--text-light);
  }
  ${Button} {
    margin-left: 0.2rem;
    margin-top: auto;
  }
  @media screen and (max-width: 580px) {
    p {
      font-size: 2rem;
    }
    ${Button} {
      margin-left: 0.5rem;
      border-radius: 25px;
      padding: 0.7rem 2rem;
    }
  }
`

const Title = styled.h4`
  font-size: 1.9rem;
  margin: 1.5rem 0 0.5rem 0.5rem;
  color: var(--text-mid);
  @media screen and (max-width: 580px) {
    font-size: 2.8rem;
  }
`
