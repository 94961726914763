import React from "react"
import styled, { css } from "styled-components"
import Link from "../components/link"
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi"

export default function Navigation({ pageContext, path }) {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? `/${path}` : `/${path}/${currentPage - 1}`
  const nextPage = `/${path}/${currentPage + 1}`

  if (isFirst && isLast) {
    return null
  }

  return (
    <NavigationContainer>
      {!isFirst && (
        <Button as={Link} to={prevPage}>
          <HiOutlineArrowNarrowLeft />
          Previous
        </Button>
      )}
      {Array.from({ length: numPages }, (_, i) => (
        <PageNumber
          as={Link}
          selected={i + 1 === pageContext.currentPage}
          key={`pagination-number${i + 1}`}
          to={`/${path}/${i === 0 ? "" : i + 1}`}
        >
          {i + 1}
        </PageNumber>
      ))}
      {!isLast && (
        <Button as={Link} to={nextPage}>
          Next <HiOutlineArrowNarrowRight />
        </Button>
      )}
    </NavigationContainer>
  )
}

const NavigationContainer = styled.div`
  margin: 4rem 0;
  display: flex;
  justify-content: center;
  a {
    margin: 0 1.5rem;
    color: var(--text-mid);
  }
  @media screen and (max-width: 480px) {
    font-size: 2.4rem;
  }
`
const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 0.1rem;
  border-bottom: 1px solid var(--text-light);
  &:first-child {
    svg {
      margin-right: 0.3rem;
    }
  }
  &:last-child {
    svg {
      margin-left: 0.3rem;
    }
  }
`
const PageNumber = styled.div`
  ${props =>
    props.selected &&
    css`
      color: var(--primary-color) !important;
    `}
`
